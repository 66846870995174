<template>
  <div>
    <div
      :class="`bg_col_fff bor_rad_8 ${
        isLandscape
          ? 'pos_fixed l_0 t_0 w_100_vh h_100_vw z_4'
          : 'h_88_vw box_sha_0_0_8_8_black_80_per m_0_16 m_t_16'
      }`"
    >
      <div class="h_100_per dis_flex flex_dir_col p_16">
        <div class="dis_flex ali_it_cen">
          <div class="flex_1 font_16 font_bold">放款走势图</div>

          <!-- 筛选 -->
          <div
            v-if="isLandscape"
            :class="`font_14 ${
              filterForm.brokerId || filterForm.productId ? 'col_2396F5' : ''
            } m_r_32`"
            @click="
              filterForm = {
                ...filterForm,
                tempFilterForm: filterForm,
                filterDialogShow: true,
              }
            "
          >
            筛选
          </div>

          <!-- 筛选 -->
          <van-popup
            v-model="filterForm.filterDialogShow"
            position="bottom"
            style="overflow: visible"
            @click-overlay="
              filterForm = {
                ...filterForm.tempFilterForm,
                filterDialogShow: false,
              }
            "
          >
            <div>
              <div class="dis_flex ali_it_cen bg_col_F5F7F9">
                <div
                  class="font_16 p_8_16 active_bg_col_ddd"
                  @click="
                    filterForm = {
                      ...filterForm.tempFilterForm,
                      filterDialogShow: false,
                    }
                  "
                >
                  取消
                </div>
                <div class="flex_1"></div>
                <div
                  class="font_16 p_8_16 active_bg_col_ddd"
                  @click="
                    filterForm = {};
                    getTrendChart();
                  "
                >
                  重置
                </div>
                <div
                  class="font_16 p_8_16 active_bg_col_ddd"
                  @click="
                    filterForm = { ...filterForm, filterDialogShow: false };
                    getTrendChart();
                  "
                >
                  确认
                </div>
              </div>

              <!-- 内容 -->
              <div class="bg_col_fff">
                <!-- 项 -->
                <div
                  class="dis_flex font_16 p_16"
                  @click="
                    filterForm = { ...filterForm, governorDialogShow: true }
                  "
                >
                  <div class="flex_1">团队</div>
                  <div>
                    {{
                      (
                        governorList.find(
                          (it) => it.value == filterForm.brokerId
                        ) || {}
                      ).label || '请选择'
                    }}
                  </div>
                </div>

                <!-- 团队 选择 弹窗 -->
                <PickEnumCustomScroll
                  :show.sync="filterForm.governorDialogShow"
                  :columns="governorList"
                  :selectedValue="filterForm.brokerId"
                  @handleConfirm="
                    (v) =>
                      (filterForm = {
                        ...filterForm,
                        brokerId: v.value,
                      })
                  "
                />

                <!-- 灰线 -->
                <div class="h_1 bg_col_eee m_0_16"></div>

                <!-- 项 -->
                <div
                  class="dis_flex font_16 p_16"
                  @click="
                    filterForm = { ...filterForm, productDialogShow: true }
                  "
                >
                  <div class="flex_1">产品</div>
                  <div>
                    {{
                      (
                        brokerProductList.find(
                          (it) => it.value == filterForm.productId
                        ) || {}
                      ).label || '请选择'
                    }}
                  </div>
                </div>

                <!-- 产品 选择 弹窗 -->
                <PickEnumCustomScroll
                  :show.sync="filterForm.productDialogShow"
                  :columns="brokerProductList"
                  :selectedValue="filterForm.productId"
                  @handleConfirm="
                    (v) =>
                      (filterForm = {
                        ...filterForm,
                        productId: v.value,
                      })
                  "
                />

                <!--  -->
              </div>

              <!--  -->
            </div>
          </van-popup>

          <!-- 选项卡 -->
          <div v-if="isLandscape" class="dis_flex m_r_32">
            <!-- 项 -->
            <div
              v-for="(item, index) in tabArr"
              :key="index"
              class="font_14"
              @click="
                tabIndex = index;
                getTrendChart();
              "
            >
              <span v-show="index > 0" class="m_0_8">/</span>
              <span :class="`${tabIndex == index ? 'col_2396F5' : ''}`">{{
                item.label
              }}</span>
            </div>

            <!--  -->
          </div>

          <!-- 全屏|关闭 -->
          <div class="font_14 col_2396F5" @click="landscapeChange()">
            {{ isLandscape ? '关闭' : '全屏' }}
          </div>

          <!--  -->
        </div>

        <!-- 图表 -->
        <Chart
          v-if="!loading"
          ref="chart"
          class="flex_1 z_0 m_t_8"
          :options="options"
        />
        <div v-else class="flex_1 dis_flex ju_con_cen ali_it_cen">
          <van-loading type="spinner" />
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!--  -->
  </div>
</template>

<script>
import { columnAmount } from '@/utils/tools';

import {
  brokerDashboardTrendChartPost,
  brokerProductList,
  brokerDashboardGovernorList,
} from '@/api/index';

export default {
  components: {
    Chart: () => import('@/components/Chart.vue'),
    PickEnumCustomScroll: () => import('@/components/PickEnumCustomScroll'),
  },
  data() {
    return {
      loading: false,
      tabIndex: 0,
      options: {},
      isLandscape: false,
      filterForm: {},

      governorList: [],
      brokerProductList: [],

      scrollTop: 0,

      //
    };
  },
  computed: {
    // 选项卡
    tabArr: () => [
      {
        label: '按周',
        value: 'week',
      },
      {
        label: '按月',
        value: 'month',
      },
    ],

    //
  },
  mounted() {
    this.getGovernorList();
    this.getBrokerProductList();

    this.getTrendChart();

    // 柱状图
    /* this.options = {
      grid: {
        left: '1%',
        top: '8%',
        right: '0%',
        bottom: '0%',
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (e) => {
          const o = e[0];
          return [`<b>${o.name}</b>`].join('<br />');
        },
      },
      xAxis: {
        type: 'category',
        inverse: true,
        data: ['张三', '李四', '王五', '狗六'],
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          type: 'bar',
          barWidth: '70%',
          itemStyle: {
            barBorderRadius: [4, 4, 0, 0],
            color: new window.echarts.graphic.LinearGradient(0, 1, 0, 0, [
              {
                offset: 0,
                color: '#91CAFA',
              },
              {
                offset: 1,
                color: '#2396F5',
              },
            ]),
          },
          data: [123, 321, 456, 654],
        },
      ],
    }; */
    //
  },
  destroyed() {
    document.body.style = null;
  },
  methods: {
    columnAmount,

    // 横屏 变化
    landscapeChange() {
      this.isLandscape = !this.isLandscape;

      // 横屏
      if (this.isLandscape) {
        this.scrollTop =
          document.documentElement.scrollTop ||
          window.pageYOffset ||
          document.body.scrollTop;
        document.body.style =
          'width: 100vh;height: 100vw;transform: translate(100vw,0) rotate(90deg);transform-origin: 0 0;';
      } else {
        document.body.style = null;
        window.scrollTo({
          top: this.scrollTop,
        });
      }

      // 刷新图表
      this.loading = true;
      this.$nextTick(() => {
        this.loading = false;
      });

      //
    },

    // 获取 进件走势图
    async getTrendChart() {
      this.loading = true;

      const data = await brokerDashboardTrendChartPost({
        condition: this.tabArr[this.tabIndex].value,
        ...this.filterForm,
      });

      this.loading = false;

      // 进件走势图
      this.options = {
        /* title: {
          text: '进件走势图',
        }, */
        legend: {
          top: '16px',
        },
        grid: {
          left: '1%',
          right: '1%',
          bottom: '4%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          confine: true,
          formatter: (e) => {
            return [
              `<b>${e[0].name}</b>`,

              ...e.map((item) => {
                const i = item.dataIndex;

                let count = 0,
                  amount = 0;
                switch (item.seriesName) {
                  case '进件':
                    count = data.loanTrendChart.countDataList[i];
                    amount = data.loanTrendChart.sumAmountList[i];
                    break;
                  case '预审':
                    count = data.preTrendChart.countDataList[i];
                    amount = data.preTrendChart.sumAmountList[i];
                    break;
                  case '审过':
                    count = data.approveTrendChart.countDataList[i];
                    amount = data.approveTrendChart.sumAmountList[i];
                    break;
                  case '放款':
                    count = data.putAmtTrendChart.countDataList[i];
                    amount = data.putAmtTrendChart.sumAmountList[i];
                    break;
                }

                return `${item.marker}${item.seriesName} 数量：${columnAmount(
                  count,
                  { fixed: 0 }
                )} 金额：${columnAmount(amount / 10000)} 万元`;
              }),

              //
            ].join('<br />');
          },
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            fontSize: this.tabArr[this.tabIndex].label == '按周' ? 14 : 10,
            rotate: 70,
          },
          data: data.dateList,
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
          boundaryGap: [0, 0.01],
        },
        series: [
          {
            name: '进件',
            type: 'line',
            itemStyle: {
              // barBorderRadius: [4, 4, 0, 0],
              color: '#2396F5',
            },
            data: data.loanTrendChart.countDataList,
          },
          {
            name: '预审',
            type: 'line',
            itemStyle: {
              color: '#5AD8A6',
            },
            data: data.preTrendChart.countDataList,
          },
          {
            name: '审过',
            type: 'line',
            itemStyle: {
              color: '#5A8DA6',
            },
            data: data.approveTrendChart.countDataList,
          },
          {
            name: '放款',
            type: 'line',
            itemStyle: {
              color: '#E6A23C',
            },
            data: data.putAmtTrendChart.countDataList,
          },
        ],
      };

      //
    },

    // 获取 产品
    async getBrokerProductList() {
      const data = await brokerProductList();

      this.brokerProductList = data.map((item) => {
        return {
          ...item,
          label: item.productName,
          value: item.productId,
        };
      });

      //
    },

    // 获取 省长
    async getGovernorList() {
      const data = await brokerDashboardGovernorList();

      this.governorList = data.map((item) => {
        return {
          ...item,
          label: item.brokerName,
          value: item.brokerId,
        };
      });

      //
    },

    //
  },
};
</script>
